import { Image } from "@chakra-ui/react";
import { t } from "i18next";
import { isEmpty } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { openNewTab } from "../../utils/UIUtils";
import useUserQuery from "../api/queries/useUserQuery";
import AddUserProfileV1BottomSheet from "../bottomSheet/AddUserProfileV1BottomSheet";

const CircleMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 88px;
`;

const ImageSvgWrapper = styled.div`
  border-radius: 50%;
  box-sizing: border-box;
  background: linear-gradient(91.59deg, rgba(255, 255, 255, 0.5) 31.85%, rgba(255, 255, 255, 0.1) 102.92%);
  border-image-source: linear-gradient(134.41deg, rgba(255, 255, 255, 0.6) 5.69%, rgba(255, 255, 255, 0) 95.22%);
  box-shadow: 0px 0px 24px 0px rgba(255, 255, 255, 0.3) inset;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 17px;
`;

const Title = styled.div`
  padding-top: 8px;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
  max-width: 88px;
  letter-spacing: 0px;
`

export default function CircleMenu({title, imageUrl, imageSvgBase64, linkPath, deeplinkPath}) {
  const navigate = useNavigate();
  const [isShowBaseProfileBottomSheet, setIsShowBaseProfileBottomSheet] = useState(false);

  const user = useUserQuery();
  const isReportPhotoCard = linkPath.includes("report-photo-card");
  const isEmptyBaseUserProfile = user && isEmpty(user.baseUserProfile);

  const handleClick = (url) => {
    // URL이 외부 링크인지 확인 (http 또는 https로 시작하는지 확인)
    const isStorePocapan = url.includes("store.pocapan.com");
    const isExternal = url.startsWith('http://') || url.startsWith('https://');
    if (isStorePocapan) {
      window.location.href = url;
    } else if (isExternal) {
      // 외부 링크일 경우 새 탭에서 열기
      openNewTab(url);
    } else if (isReportPhotoCard && isEmptyBaseUserProfile) {
        setIsShowBaseProfileBottomSheet(true);
    } else {
      // 내부 링크일 경우 React Router의 useNavigate 사용
      navigate(url);
    }
  };

  return(
    <CircleMenuWrapper onClick={() => handleClick(linkPath)}>
      {

        imageUrl ?
          <Image width={"88px"} height={"88px"} src={imageUrl} objectFit={"cover"} alt={title} />
        : imageSvgBase64 && <ImageSvgWrapper>
          <div dangerouslySetInnerHTML={{__html: atob(imageSvgBase64)}}/>
        </ImageSvgWrapper>
      }
      <Title>{title}</Title>
      {
        (isReportPhotoCard && isEmptyBaseUserProfile && isShowBaseProfileBottomSheet) &&
        <AddUserProfileV1BottomSheet
          title={t("createFeedUserProfilePage.title")}
          userProfile={user.baseUserProfile}
          isShowProfileBottomSheet={isShowBaseProfileBottomSheet}
          setIsShowProfileBottomSheet={setIsShowBaseProfileBottomSheet}
          onBackDropClick={() => setIsShowBaseProfileBottomSheet(false)}
          category={"base"}
          disableDrag={false}
        />
      }
    </CircleMenuWrapper>
  )
}
