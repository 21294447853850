
export default class PhotoCardResponse {
  constructor({ photoCardId, memberId, producibleType, producibleId, filename, name, visibility, artistId, memberName, categoryName, categoryEmoji, eventId, eventName, eventSlug, pocaname, pictureUrl, smallThumbnailUrl, mediumThumbnailUrl, largeThumbnailUrl, collectedCount, isWished }) {
    Object.assign(this, { photoCardId, memberId, producibleType, producibleId, filename, name, visibility, artistId, memberName, categoryName, categoryEmoji, eventId, eventName, eventSlug, pocaname, pictureUrl, smallThumbnailUrl, mediumThumbnailUrl, largeThumbnailUrl, collectedCount, isWished });
  }

  getCategoryDisplay() {
    return `${this.categoryEmoji} ${this.categoryName}`;
  }
}
