export const zhCN = {
  translation: {
    pocapan: 'Pocapan',
    requestFailed: '请求失败。',
    next: '下一步',
    done: '完成',
    save: '保存',
    confirm: '完成',
    cancel: '取消',
    login: '登录',
    logout: '登出',
    photoCardCount: '{{count}}个',
    cardsCount: '{{count}}张',
    entire: '全部',
    report: '举报',
    send: '发送',
    megaphone: '扩音器',
    picture: '照片',
    photoCard: '小卡',
    poca: '小卡',
    anonymous: '匿名',
    comment: '评论',
    like: '喜欢',
    leave: '离开',
    entireMembers: '所有成员',
    sendMessage: '发送消息',
    accept: '接受',
    decline: '拒绝',
    yes: '是',
    no: '否',
    select: '选择',
    tabs: {
      home: '首页',
      feed: '社群',
      pocapan: '小卡图鉴',
      tradingPhotoCard: '贸易场',
      reportPhotoCard: '建议小卡',
      profile: '我的',
      notifications: '通知'
    },
    feedPage: {
      title: '社群',
      defaultProfile: '个人资料 🔒',
      alertMarquee: '为了安全和愉快的交易，请使用贸易场进行小卡交易。简单的交易帖子将被删除，不另行通知！',
      onlyFandom: '仅查看粉丝圈的帖子',
      newPostNotification: '查看新帖子',
      uploadToastMessage: '上传完成！',
      uploadToastShowPost: '查看',
      fandomFeed: '贸易场'
    },
    feedIntroPage: {
      title: '社区',
      introTitle: '现在你可以在Pocapan的\n社区中尽情玩耍！',
      firstRowTitle: '使用匿名功能秘密分享！',
      firstRowDescription: '只有同一个粉丝圈的人才能看到我的帖子！',
      secondRowTitle: '使用扩音器与大家分享好消息！',
      secondRowDescription: '你甚至可以用引用添加自己的想法！',
      thirdRowTitle: '使用Only Fandom功能在粉丝圈内分享！',
      thirdRowDescription: '[好友专用]功能也在准备中！',
      confirmFeedAgreement: '视为您已同意更新的条款。'
    },
    feedReportPage: {
      pageTitle: '举报',
      menuInsult: '侮辱/谩骂',
      menuFalseInformation: '散布虚假信息',
      menuObscenity: '淫秽内容',
      menuOverPosting: '广告/评论垃圾信息',
      menuPromotion: '推广',
      menuOther: '其他',
      author: '作者',
      content: '帖子内容',
      selectReportReason: '请选择举报原因'
    },
    addPostPage: {
      pageTitle: '创建新帖子',
      title: '创建社交资料',
      titlePlaceHolder: '点击此处添加标题',
      contentPlaceHolder: '你今天过得怎么样？',
      contentPicturesError: '上传最多4张图片，总大小限制50MB',
      disclaimer: '请避免留下可能让他人不适的评论，例如粗话或侮辱性语言。如果被举报，你的社区使用可能会受到限制。',
      onlyFandom: '仅对{{fandom}}可见',
      onlyFandomDescription: '设置为仅显示选定粉丝圈！',
      addPhotoCard: '添加小卡',
      addPhotoCardBoard: '小卡图鉴',
      friendsOnly: '好友(即将推出)'
    },
    postDetailPage: {
      pageTitle: '发布{{title}}',
      deleteMenu: '删除',
      deletedDescription: '评论所在的原始帖子已被删除。',
      postOnFeed: '也将此评论发布到社群中',
      alertDeletedPost: '原始帖子已被删除。',
      commentPlaceHolder: '留下评论',
      sourcePostDescription: '评论<bold>{{post}}</bold>',
      followings: '<bold>{{count}}</bold> Followings',
      followers: '<bold>{{count}}</bold> Followers'
    },
    userFeedProfilePage: {
      pageTitle: '社交资料',
      postTab: '帖子',
      navigateTradingProfile: "看看{{nickname}}的交易资料",
      menuChangeNickname: "修改昵称",
      menuHiddenTradingProfile: "设为私密交易资料",
      menuOpenTradingProfile: "设为公开交易资料",
    },
    addPhotoCardPage: { pageTitle: '添加小卡' },
    post: {
      emptyPost: '没有可用帖子',
      megaphonedPost: '{{author}}正在使用扩音器',
      commentedPost: '评论{{content}}',
      deletedPost: '你评论的帖子已被删除。',
      deletedCommentMenu: '删除',
      confirmDelete: '确定要删除吗？',
      reportPostMenu: '举报帖子',
      reportUserMenu: '举报用户',
      deletePostMenu: '删除',
      onlyFandom: 'Only\n{{fandom}}',
      reply: '回复'
    },
    createFeedUserProfilePage: {
      pageTitle: '创建社交资料',
      title: '创建社交资料',
      description: '为了社群中的安全和隐私，只能使用社交资料进行交流！',
      nickNamePlaceHolder: '请输入你的名字'
    },
    reportBottomSheet: { megaphone: '立即使用扩音器', unmegaphone: '取消扩音器', quotes: '添加引用和扩音器' },
    exploreTradingPhotoCardBottomSheet: {
      errorNoProfileToast: '请求失败。请确保您的资料已创建。',
      tradingPhotoCardInfo: '交易卡详情',
      sendMessage: '发送消息'
    },
    photoCardBottomNav: {
      collectedPhotoCardCount: '{{collectedCount}} / {{totalCount}}',
      collectedPhotoCardPercentage: '{{member}}的小卡{{percentage}}%',
      editModeButton: '编辑持有的小卡'
    },
    introPage: { startButton: '开始Pocapan！' },
    requestVerificationCodePage: {
      emailErrorMessage: '请确保电子邮件地址格式正确',
      title: '请提供您将用于登录的电子邮件',
      description: '别担心，不会与任何人分享！',
      placeHolder: '点击此处输入电子邮件'
    },
    loginWithCodePage: {
      title: '请输入验证码',
      description: '已发送至{{email}}！',
      notReceivedMessage: '未收到验证邮件？',
      sendAgainMessage: '重发',
      successSendAlert: '电子邮件已重新发送。',
      failedSendAlert: '重发失败。请稍后再试。',
      verificationFailedAlert: '验证失败。请再检查。'
    },
    welcomePage: { title: '很高兴认识你！', description: '我会让你的追星更方便。' },
    selectArtistPage: {
      pageName: '选择艺术家',
      title: '请选择一个艺术家',
      description: '如果我们收到足够的请求，我们将添加不存在的团体！',
      errorAlert: '请选择一个艺术家。',
      whoIsNext: '下一个是谁？'
    },
    selectMemberPage: {
      pageName: '选择成员',
      title: '选择你最喜欢的表情包',
      errorAlert: '请至少选择一个成员。',
      next: '完成',
      confirmed: '成员更改完成'
    },
    photoCardPage: {
      pageName: '小卡图鉴',
      title: '选择你拥有的小卡',
      description: '未选择的小卡将被标记为未拥有的小卡！',
      errorAlert: '请至少选择一个成员。',
      navigationSkip: '稍后完成',
      navigationReport: '举报',
      navigationEdit: '编辑',
      confirmErrorAlert: '请至少选择一张小卡。',
      listTitle: '小卡图鉴',
      bannerTitle: '创建你的小卡图鉴',
      showPhotoCardsOnFeed: '在社群中查看我拥有的小卡',
      manageArtist: '💖管理艺术家',
      emptyPhotoCards: '没有可用的小卡图鉴',
      showColorMode: '以彩色模式查看',
      discardAlertDialog: '你有未保存的更改。\n取消编辑？',
      likedArtistPhotoCardList: '{{artist}}的小卡',
      filter: "筛选",
    },
    checkPhotoCardBottomSheet: { title: '小卡列表', report: '举报' },
    emptyPhotoCard: {
      title: '勾选的小卡将显示在这里',
      description: '检查你拥有的小卡！',
      startCollectPhotoCard: '去检查'
    },
    myPhotoCardBottomSheet: {
      smallSize: '小视图',
      middleSize: '中等视图',
      largeSize: '大视图',
      shareImageTwitterButton: '在Twitter上分享此图片',
      collectedPhotoCards: '已收集的小卡',
      unCollectedPhotoCards: '未拥有的小卡',
      wishedPhotoCards: '想要的小卡',
      showPhotoCardName: '查看小卡名称',
      createPhotoCardBoard: '创建小卡图鉴',
      shareTwiiterText: "https://twitter.com/intent/tweet?text=1. 使用下载图标保存图片。%0A2. 如果你有iPhone，在'文件'应用中找到图片。%0A3. 在Twitter上分享并向你的朋友炫耀。%0A粉丝们，Pocapan&url=pocapan.com"
    },
    reportPhotoCardPage: {
      pageName: '举报一张小卡',
      title: '举报一张小卡',
      categoryDescription: '这是什么类型的小卡？',
      eventDescription: '这是什么类型的小卡？',
      requiredMissingAlert: '请选择全部。',
      reportDoneAlert: '谢谢！我们会尽快检查并更新！',
      selectPlaceHolder: '点击此处找到我们！',
      directlyInputOption: '直接输入',
      reportDone: '举报',
      duplicatedAlert: '上传照片...'
    },
    userProfilePage: {
      pageName: '我的Pocapan',
      title: '我的',
      myPhotoCard: '我的小卡',
      changeMember: '更改你的收集成员',
      initializePhotoCards: '重置我的小卡',
      notice: '通知',
      terms: '服务条款',
      privacy: '隐私政策',
      deleteAccount: '删除账户',
      logoutConfirmAlert: '你想要登出吗？',
      deleteAccountConfirmAlert: '删除账户？',
      initializePhotoCardsAlert: '所有你的小卡将被重置。你确定要继续吗？',
      errorIntializePhotoCardsAlert: '请稍后再试。'
    },
    addTradingPhotoCardPage: {
      exchangePageName: "出售我的小卡",
      salesPageName: "交换我的小卡",
      pageName: '添加交易的小卡',
      title: '请注册您想要交易的小卡',
      exchangeTitle: "请选择您要提供交换的小卡",
      salesTitle: "请选择要出售的小卡",
      searchInputPlaceholder: '按名称或专辑名称搜索',
      countButton: '{{count}} 添加',
      myPhotoCardTitle: '我的一张小卡',
      searchPhotoCardTitle: '所有小卡'
    },
    childLoginPage: {
      pageName: '监护人未授权使用限制',
      title: '因缺乏监护人同意而限制访问',
      description: '对于14岁以下且无监护人同意的用户，在获得同意前访问受限。监护人同意需要在5天内提供。如果未提供同意，账户将被删除，所有信息将被销毁。(限制日期: MM/DD/YYYY)',
      enableAccountGuide: '监护人同意通过电子邮件处理。请让您的家长直接联系我们：pocapan.official@gmail.com',
      deleteAccount: '删除账户',
      backToIntro: '返回首页'
    },
    blockUserPage: {
      pageName: '访问受限',
      title: '访问受限',
      description: '由于多名用户举报，访问受限。如有疑问，请发送电子邮件至pocapan.official@gmail.com'
    },
    requiredLoginPage: {
      pageName: '需要登录',
      title: '需要登录',
      description: '由于未知错误，你已被登出。请重新登录以确保安全体验。'
    },
    emptyTradingPhotoCard: {
      title: '{{nickname}}，请添加你想交易的小卡！',
      description: 'Pocapan将帮助你找到交易匹配。',
      addTradingPhotoCard: '添加交易的小卡'
    },
    emptyExploreTradingPhotoCard: {
      title: '尚未发布任何小卡。',
      description: '仅显示他人在交易的小卡',
      addTradingPhotoCard: '添加交易的小卡'
    },
    emptyWishedTradingPhotoCard: {
      title: '尚未想要的小卡！',
      description: '一目了然地收集和查看你想要的小卡。',
      addTradingPhotoCard: '添加交易的小卡'
    },
    emptyWishedMatchedTradingPhotoCard: {
      title: '尚未有想要的交易！',
      description: '当你对交易犹豫不决时使用此功能。',
      addTradingPhotoCard: '添加交易的小卡'
    },
    emptyMessages: { title: '尚未有消息！', button: '浏览交易的小卡' },
    forceToUpdate: {
      pageTitle: '💌 来自Pocapan的信 💌',
      title: '随着应用更新，新的社群即将到来💖',
      button: '去更新',
      firstRowDescription: '大家好，我是波卡潘团队！\n' +
        '我们推出 Pocapan 已经一年了。\n' +
        '我们要感谢大家对我们的关注\n' +
        '是你们一如既往的支持帮助我们走到今天。\n' +
        '波卡潘已经从一个照片卡片交流平台发展成为\n' +
        '以粉丝为中心的美德文化之家。',
      secondRowDescription: '我们正在开设一个新的社群，让交流更加轻松自由。',
      thirdRowDescription: '我们诚挚地邀请您加入我们。'
    },
    tradingPhotoCardPage: {
      pageTitle: '贸易场',
      exchangeTabName: '交换匹配',
      salesTabName: '交易/购买列表',
      manageMyTradingPhotoCard: '我的交易小卡',
      marqueeText: '🔍停止搜索！通过贸易场轻松找到你的交易伙伴！Pocapan对交易问题不负责，请谨慎！',
      thisCardOnlyForSale: '选定的小卡被设置为仅出售卡。',
      tradingPhotoCardChatRooms: '与这张小卡的聊天室',
      noChatRoom: '目前没有活跃的聊天室。',
      exchangeEventTradingPhotoCards: '同一事件的可交易小卡',
      emptyEventTradingPhotoCards: '尚无同一事件的小卡。',
      exchangeTradingPhotoCards: '可交易的小卡',
      addProfileBottomSheetTitle: '设置你的交易资料',
      tradingManagerBarTitle: '我的交易卡',
      tradingManagerBarManage: '编辑',
      aggregatedPhotoCardCardTitle: '可供我交易',
      showUserProfilesBottomSheetTitle: '选择要交易的{{fandom}}？',
      notAvailableExchangeTradingPhotoCards: '尚无可交易的小卡 🥲',
      offetSalesTradingPhotoCardsRow1: '也可以直接从{{fandom}}购买。',
      offetSalesTradingPhotoCardsRow2: '您也可以直接购买',
      offetSalesTradingPhotoCardsRow3: ' ',
      matchedTradingPhotoCardBottomDrawerTitle: '交易卡详情',
      matchedTradingPhotoCardMine: '我有',
      matchedTradingPhotoCardTarget: '想要',
      addBlackListMenu: '不再接受这张卡的匹配',
      reportMenu: '举报',
      reMatching: '再次寻找',
      exchange: '交易',
      sale: '出售',
      salesSwitchTitle: '显示仅出售的小卡',
      exchangeTabTitle: '{{saleType}}的小卡由{{fandom}}出售',
      salesSwitchDescription: '发送直接消息以接收交易！',
      saleBadge: '出售',
      excludeMatching: '排除',
      allowMatching: '允许',
      offerPrice: '出价',
      offerPriceWithEmoji: '出价🧚‍♂️',
      tradingPhotoCardInfoBar: '我的交易卡',
      noProfileToast: '请求失败。请确保您的资料已创建。',
      askPrice: '询价',
      purchaseWithPrice: '{{price}} 购买'
    },
    myTradingPhotoCardsPage: { pageName: '设置交易小卡' },
    tradingPhotoCardChatRoom: {
      pageTitle: '消息',
      noticeDeletedUser: '成员已被停用。如有进一步疑问，请联系Pocapan的邮件。',
      noticeLeftUser: '对方已结束聊天。',
      leaveChatRoomDialogTitle: '离开聊天室？',
      leaveChatRoomDialogDescription: '离开将结束交易，您将不再收到对方的消息。',
      leaveChatRoomDialogConfirm: '离开聊天室',
      saleTradingMenu: "销售中",
      purchasingTradingMenu: "购买",
      completedTradingMenu: "交易完成",
    },
    photoCardDetailPage: {
      firstCaptorTitle: '这张卡的小卡精灵💖',
      collected: '拥有',
      collectedCount: '{{count}} 张小卡',
      notCollected: '未拥有',
      changeToNotCollected: '改为未拥有',
      changeCollectedCount: '设置数量',
      exchangeTradingPhotoCardTitle: '立即用以下卡片进行交易！',
      saleTradingPhotoCardTitle: '{{fandom}}正在出售的小卡',
      emptysaleTradingPhotoCards: '目前没有人出售这张卡。',
      feedTitle: '{{fandom}}场',
      feedDescription: '想了解更多吗？查看社群！',
      showAllFeed: '查看全部',
      firstCaptorBadge: '🧚首位捕获者',
      changeCollectedBottomSheetTitle: '你有卡！',
      changeCollectedBottomSheetDescription: '请输入你拥有的卡数量。',
      tradeButton: "交易",
      title: '{{memberName}的{{categoryName}}{{categoryEmoji}}'
    },
    myPhotoCards: {
      pageName: '交易历史',
      totalCount: '总计{{count}}',
      tradingStatusSelectorOngoing: '正在进行的交易',
      tradingStatusSelectorCompleted: '已完成的交易',
      tradingPhotoCardBoxByPhotoCardBadge: '聊天室{{count}}',
      exchangingPhotoCardsTabName: '交易',
      exchangingPhotoCardsTabEmptyCaseTitle: '尚未交易任何小卡',
      exchangingPhotoCardsTabEmptyCaseButton: '去交易',
      exchangingPhotoCardsTabReviewNotYet: "为 “交易 ”发送评价的功能仍在开发中",
      sellingPhotoCardsTabName: '出售',
      sellingPhotoCardsTabEmptyCaseTitle: '尚未出售任何小卡',
      sellingPhotoCardsTabEmptyCaseButton: '去出售',
      purchasingPhotoCardsTabName: '',
      purchasingPhotoCardsTabEmptyCaseTitle: '尚未购买任何小卡',
      purchasingPhotoCardsTabEmptyCaseButton: '去购买',
      purchasingPhotoCardsTabSaleBadge: '出售中',
      purchasingPhotoCardsTabEmptyExchangeBadge: '交易中',
      chatRoomPageName: '活跃的聊天室',
      chatRoomPageSoldOutButton: '评价功能即将推出🖤',
      chatRoomPageCompletedButton: '已完成的交易',
      chatRoomPageConvertToSaleButton: '改为出售',
      chatRoomPageConvertToExchangeButton: '改为交易',
      chatRoomPageEmptyCase: '目前没有活跃的聊天室',
      chatRoomExchangeDialogTitle: '改为仅交易？',
      chatRoomExchangeDialogDescription: '现有的活跃聊天室将关闭，对方会收到通知。对话仍可以继续！',
      chatRoomCompleteDialogTitle: '这是一个已完成的交易吗？',
      chatRoomCompleteDialogDescription: '评价功能即将推出！',
      chatRoomSaleDialogTitle: '改为仅出售？',
      chatRoomSaleDialogDescription: '现有的活跃聊天室将关闭。如果未输入，将标记为“出价”。',
      chatRoomSaleDialogInputPlaceholder: '请输入金额。',
      changePriceDialogTitle: '编辑价格？',
      changePriceDialogDescription: '如果未输入价格，将显示为“出价”。',
      onlySale: '出售',
      editPrice: '编辑',
      doneAndSendReview: "关闭并撰写评价",
      sentReviewButton: "查看我给他人的评价",
      sendReviewButton: '💘写交易评价💘',
      receivedReviewButton: "查看他人给我的评价",
    },
    tradingReportPage: {
      pageName: '举报到Pocapan',
      title: 'Pocapan举报中心',
      descriptionFirstRow: '• 举报首先由自动系统处理。对于重复举报，管理员将进行审核并采取进一步行动。',
      descriptionSecondRow: '• 如果举报导致法律问题，Pocapan不承担任何责任。如有必要，可提供个人信息和聊天内容。',
      reportCode: '举报代码',
      reportDescription: '举报详情',
      reportDescriptionPlaceholder: '请输入详细信息。',
      reportDescriptionEmptyErrorMessage: '请输入内容。',
      reportAttchment: '附件',
      reportAttchmentPlaceHolder: '请附加文件。',
      reportUserEmail: '举报者的电子邮件地址',
      reportUserEmailEmptyErrorMessage: '请输入电子邮件。',
      reportUserEmailFormatErrorMessage: '请检查电子邮件格式。',
      reportAgreement: '我同意提供个人信息和审核聊天内容。',
      reportSubmit: '提交',
      successToastMessage: '举报成功提交。',
      failedToastMessage: '请求失败。请稍后再试。'
    },
    blacklistCards: {
      manage: '编辑',
      exclude: '设置排除的小卡',
      pageName: '设置排除的小卡',
      title: '设置排除的小卡',
      description: '选择要从匹配中排除的卡。拥有的卡将自动排除。'
    },
    agreements: {
      baseAgreementBottomSheetTitle: '请查看并接受更新的服务条款。',
      baseAgreementBottomSheetDescription: '您有权拒绝，但拒绝可能会限制您对服务的访问。',
      baseAgreementBottomSheetButton: '开始',
      baseAgreementAbove14: '请查看并接受更新的服务条款。',
      baseAgreementBottomAgreeAllButton: '全部同意',
      baseAgreementRequiredCheckBox: '（必填）',
      baseAgreementRequiredAgreement: 'Pocapan条款和条件',
      baseAgreementOptionalCheckBox: '（可选）',
      baseAgreementPromotionalAgreement: '同意接收营销信息'
    },
    errorBoundary: { title: '发生错误。', description: '请返回首页重新尝试。', button: '返回首页' },
    newTradingPhotoCardsHorizontalCard: {
      authorName: '贸易场',
      authoredAt: '刚刚',
      newSales: '新上市的小卡🛍️' ,
      newExchanges: '新上市的交易小卡↔️'
    },
    myUserProfileList: {
      privateProfile: '社交资料',
      createPrivateProfile: '社交资料',
      tradingProfile: '交易资料',
      addProfile: '添加资料',
      changeProfile: '编辑社交资料'
    },
    profiles: {
      defaultButton: '开始',
      nickNameButton: '以{{nickname}}开始',
      hasBatchimNickNameButton: '以{{nickname}}开始',
      emptyNicknameErrorMessage: '请输入昵称。',
      duplicatedNickName: '这个昵称已被使用。',
      failedToCreateAlert: '注册失败。',
      mergedNicknameToastMessage: '资料已合并。',
      title: '编辑社交资料',
      inputNickNamePlaceholder: '请输入昵称。',
      mergeProfilePageTitle: '合并资料',
      mergeProfileTitle: '多粉丝！请选择一个资料。',
      mergeProfileDescription: '使用贸易场时无需再切换艺术家！',
      mergeProfileFirstDescriptionRow: '• 您可以在“我的 > 艺术家管理”中管理艺术家。',
      mergeProfileSecondDescriptionRow: '• 多资料支持将会在重新整理后重新推出。',
      mergeProfileThirdDescriptionRow: '• 现有的对话将反映更新后的昵称。',
      chooseMergedProfile: '选择',
      createNewNickname: '创建新昵称'
    },
    notifications: {
      pageTitle: '通知',
      messagesTab: '消息',
      notificationsTab: '通知',
      notificationsTitle: '通知列表正在准备中。',
      notificationsDescription: '正在发送消息/评论/喜欢通知！',
      messageCategoryAll: "全部",
      messageCategorySelling: "出售",
      messageCategoryPurchasing: "购买",
      messageCategoryExchanging: "交易",
    },
    selectArtists: {
      initArtistTitle: '选择要重置的艺术家',
      initArtistDescription: '仅拥有的小卡将被重置！交易记录保持不变。',
      photoCardsCount: '{{count}} 张小卡'
    },
    tradingUserListPage: {
      pageName: '同一事件的小卡',
      title: '你想要与哪个{{fandom}}交易？',
      batchimTitle: '你想要与哪个{{fandom}}交易？',
      orRow: '或',
      saleSubTitleRow: '想要快速购买吗？支付额外费用...',
      saleTitleFirstRow: '出售中',
      saleTitleSecondRow: '可用于{{fandom}}！'
    },
    addTradingPhotoCard: {
      editNewTradingTitle: '注册完成😍',
      editNewTradingSubTitle: '这是仅出售吗？',
      editNewTradingDescription: '如果仅出售，请输入价格！',
      addButton: '添加',
      collectedBadge: '拥有',
      emptyCollectedCaseTitle: '没有拥有的小卡。',
      emptyCollectedCaseDescription: '您可以搜索并交易未注册的小卡！',
      emptyCollectedCaseButton: '注册小卡'
    },
    addPhotoCard: { searchPlaceholder: '🔍 按名称或专辑标题搜索', description: '只能添加一张小卡。' },
    chatRoom: {
      read: '已读',
      copyMessageToClipboard: '消息已复制。',
      imageMenu: '照片',
      showTraderPhotoCards: '查看对方的交易小卡',
      placeHolder: '输入消息',
      disabledPlaceHolder: '无法聊天',
      traderPhotoCardsBottomSheetTitle: '{{nickname}}的交易小卡',
      traderPhotoCardsBottomSheetEmptyCase: '没有可交易的小卡。'
    },
    myCollectedPhotoCardsStat: {
      authorName: '{{nickname}}的小卡图鉴',
      authoredAt: '刚刚',
      myCollectedPhotoCards: '我拥有的小卡',
      myCollectedPhotoCardsCount: '{{count}}',
      mostCollectedMember: '收集最多的成员',
      hideMenu: '从社群中隐藏',
      showAllButton: '查看全部'
    },
    myPage: {
      pageName: '我的',
      title: '我的',
      tradingsRow: '交易历史',
      wishedRow: '我的愿望清单',
      manageArtistsRow: '我的艺术家',
      manageMembersRow: '我的偏爱',
      settings: '设置',
      manageAccount: '账户设置',
      notice: '公告',
      report: '发送反馈'
    },
    manageArtistPage: {
      pageName: '我的艺术家',
      likedDays: '作为{{fandom}}粉丝{{likedDays}}天💖',
      dislikeArtist: '退出粉丝',
      likeArtist: '添加新粉丝',
      atLeastOneMoreArtistAlert: '你必须至少有一个艺术家。',
      pleaseCheckInvalidDateAlert: '请再检查日期。',
      disLikeArtistDialogTitle: '谢谢你喜爱{{artist}}！',
      disLikeArtistDialogDescription: '你的信息已保存。随时回来！',
      disLikeArtistDialogConfirm: '谢谢你喜爱{{artist}}！',
      disLikeArtistDialogCancel: '谢谢你喜爱{{artist}}！',
      changeStartLikedDateDialogTitle: '你想更改{{fandom}}的粉丝日期吗？',
      changeStartLikedDateDialogConfirm: '更改',
      addLikedArtist: '添加'
    },
    manageMemberPage: { pageName: '我的偏爱' },
    myWishedPage: {
      pageName: '我的愿望清单',
      wishedPhotoCardsTab: '小卡',
      wishedExchangesTab: '交易',
      wishedSalesTab: '出售',
      createPhotoCardBoard: '用愿望清单创建小卡图鉴',
      emptyWishedSalesTradingPhotoCardTitle: '尚无愿望出售小卡！',
      emptyWishedSalesTradingPhotoCardDescription: '将你想购买的小卡添加到这里'
    },
    manageAccountPage: {
      pageTitle: '账户设置',
      identityVerificationTitle: '获取蓝色徽章',
      identityVerificationDescription: '验证你的身份以获取蓝色徽章！',
      identityVerificationDone: '已获取',
      identityVerificationIncomplete: '未获取',
      countryTitle: '访问国家',
      countryDescription: '我们会帮你找到同一国家的粉丝',
      localeTitle: '服务语言',
      localeDescription: '设置服务语言',
      promotionTitle: '营销通知',
      promotionAgreed: '启用',
      promotionDisagreed: '禁用',
      initializePhotoCards: '重置小卡',
      deleteAccount: '删除账户',
      logout: '登出',
      appVersion: '应用版本',
      identityVerificationDialogTitle: '用新号码重新验证？',
      identityVerificationDialogDescription: '{{phoneNumber}}\n{{certifiedAt}} 验证完成',
      identityVerificationDialogYes: '是的，我的号码已更改',
      identityVerificationDialogNo: '否，保持当前号码'
    },
    emptyMatchedTradingPhotoCard: {
      title: '未找到匹配的小卡',
      description: '尝试添加其他交易小卡！',
      reMatching: '再次搜索'
    },
    onGoingMatchingTradingPhotoCard: { title: '正在寻找需要您所选小卡的粉丝！', description: '一旦找到匹配，您将收到通知。' },
    changeCountry: {
      globalFeedTitle: "全球社群",
      globalCountyDescription: "{{country}}和國際粉絲帖子。",

      supportedCountyTitle: "{{country}}社群",
      supportedCountyDescription: "{{country}}粉絲互動的專屬空間！",

      unSupportedCountryTitle: "{{country}}社群 (即將推出)",
      unSupportedCountryDescription: "當更多{{country}}粉絲聚集時開放！",

      localeDrawerFirstRow: "請選擇 Pocapan 的語言",
      localeDrawerLastRow: "다른 언어는 계속 추가 됩니다!",

      countryDrawerFirstRow: "設置你的接入國家。\n你可以每週更改一次接入國家。",

      changingAlertTitle: "將接入國家更改為{{country}}嗎？",
      changingAlertDescription: "你可以在一週內再次更改。",

      notAllowedChangingAlertTitle: "無法更改接入國家。",
      notAllowedChangingAlertDescription: "你可以每週更改一次接入國家。如果需要緊急更改，請通過電子郵件聯繫我們。",
    },
    TradingUserProfilePage: {
      pageTitle: "交易资料",
      reviewsCount: "{{count}} 评价",
      reviewColorText: '接近<color>{{color}}</color>',
      mannerSignal: "礼貌灯号",
      saleTab: "正在出售的商品",
      exchangeTab: "正在交换的商品",
      saleEmptyCards: "没有待售的小卡",
      exchangeEmptyCards: "没有待交换的小卡",
      statTradingReviews: "评价",
      statLastLogin: "上次活跃",
      statResponseRate: "响应",
    },
    TradingUserProfileSettingsPage: {
      changeProfileImage: "更换个人图片",
      pageTitle: "设置交易资料",
      add: "添加",
      nickname: "昵称",
      changeNicknameDialogTitle: "编辑昵称",
      placeHolderNickname: "请输入昵称。",
      description: "简介",
      changeDescriptionDialogTitle: "编辑简介",
      placeHolderDescription: "请输入您的简介。",
      manageTradingCards: "管理交易小卡",
      manageTradingHistories: "查看交易历史",
      manageTradingReviews: "我的评价",
    },
    SelectTradingPhotoCardReviewPage: {
      pageTitle: "选择要完成的小卡",
      title: "请选择一个已完成贸易的小卡",
      skip: "还没有小卡完成贸易",
    },
    SelectReviewChatRoomPage: {
      title: "请选择与您进行贸易的人",
      dialogText: "小卡贸易已完成",
      skip: "我不想选择",
    },
    ReviewIcon: {
      red: "不好",
      yellow: "好",
      green: "非常好",
    },
    ReviewColorText: {
      red: "紅色",
      yellow: "黃色",
      green: "綠色",
    },
    ReviewFeedbackOptions: {
      DetailedDescription: "🧸物品状况与描述一致",
      IncludesBonus: "🎁 还附带了额外物品",
      Friendly: "🫶 态度友好，举止得体",
      QuickResponse: "⚡️ 回复迅速",
      WannaTradeAgain: "🙌 一定会再次与他贸易",

      DifferentFromDescription: "😵‍💫 商品与描述不符",
      BadPackaging: "🕸️ 包装状况不佳",
      Unfriendly: "🧟‍♀️ 不友好，态度恶劣",
      SlowResponse: "🤐 他们看了我的聊天记录后就不回复了",
      UnfairCancellation: "🫥 单方面取消贸易",
    },
    AddTradingPhotoCardReviewPage: {
      title: "您与 {{nickname}} 的交易如何？",
      description: "请选择一个方式标志！对方看不到。",
      redReviewTitle: "你不喜欢什么？",
      yellowReviewTitle: "好在哪里？",
      greenReviewTitle: "最好的部分是什么？",
      blacklistReviewRating: "我不想再和这个人打交道了。",
      reviewContentTitle: "您还有什么想说的吗？",
      reviewContentDescription: "留下评价，改善您自己的贸易之光！",
      reviewContentPlaceHolder: "输入您想对对方说的话。",
      done: "已发送评价",
      doneDialogTitle: "感谢您撰写评价。",
      doneDialogDescription: "多亏了你们，Pocapan 将更加和平！",
      notConfirmedTradingButton: "我以前从未与此人做过贸易。",
    },
    TradingPhotoCardReviewDetailPage: {
      sentTitle: "我向对方发送了评价",
      receivedTitle: "对方已收到您的评价！",
      confirm: "给对方留下评价",
      notConfirmedTradingButton: "我从未与此人做过贸易",
      rejctDialogTitle: "以前从未与{{nickname}}进行过贸易？"
    },
    TradingPhotoCardReviewsPage: {
      pageTitle: "评价列表",
      feedbackOptionsTitle: "大家说这个不错！",
      reviewsTitle: "他人留下的评价",
      emptyReviews: "暂时还没有评价",
    },
    TradingPhotoCardFilterBottomDrawer: {
      title: "筛选",
      identityVerifiedTitle: "본인 인증",
      identityVerifiedRadio: "본인 인증",
      categoryTitle: "小卡类型",
      eventTitle: "包含小卡的活动",
    },
    PhotoCardFilterBottomDrawer: {
      title: "筛选",
      categoryTitle: "小卡类型",
      eventTitle: "包含小卡的活动",
      initButton: "重置",
      defaultCategory: "普通卡",
      specialCategory: "未公开卡",
      mdCategory: "周边卡",
      tradingCategory: "换卡",
      emptyCategory: "没有符合所选类型的小卡。",
    },
    ExchangesTabPanel: {
      matchingSubTab: "可交换的小卡",
      chattingSubTab: "筛选",
      availablePoca: "匹配列表",
      chatRoomsBoxText:	"有{{count}}个正在用这张小卡交易的聊天房间。",
      filter: "聊天列表",
    },
    SalesTabPanel: {
      purchaseSubTab: "购买小卡",
      saleSubTab: "我的出售小卡",
      filter: "筛选",
    },
    ConfirmNewSalesTradingPhotoCardsPage: {
      title: "请输入销售价格",
      description: "如果未输入价格，将显示为“出价”",
      removeAllDialog: "小卡已全部删除。请重新选择。"
    },
    DoneTradingPhotoCardsPage: {
      exchangeTitle: "已注册为交换小卡！",
      saleTitle: "已注册为销售小卡！",
      description: "别错过对方的消息！请开启通知功能！",
    },
    TradingPhotoCardPageV2: {
      chatRoomCountCard:	"聊天 {{count}}",
      exploreCard:	"浏览",
      addCard:	"添加",
      sellPhotoCardButton:	"出售我的小卡",
      exchangePhotoCardButton:	"交换我的小卡",
    },
    EventCategory: {
      album: { title: "💿专辑活动", description: "小卡随专辑发行获得" },
      offline: { title: "🎤官方线下活动", description: "演唱会／粉丝见面会／快闪活动小卡" },
      ADs: { title: "💄商业活动", description: "与艺人商业活动相关的小卡" },
      misc: { title: "🪪粉丝俱乐部 & 应援棒 & 季刊", description: "小卡来自粉丝俱乐部、应援棒、季刊" },
      content: { title: "📺媒体内容", description: "与内容、写真书、杂志等媒体相关的小卡" },
    },
    ReportPhotoCardActivitiesPage: {
      pageName: "新增缺失的小卡",
      title: "此小卡来自于哪个活动？",
      unknownActivity: "不确定"
    },
    ReportPhotoCardCandidatesPage: {
      pageName: "新增缺失的小卡",
      title: "下面的小卡有相同的吗？",
      emptyTag: "无标签",
      addManullay: "新增您的小卡"
    },
    PhotoCardCategory: {
      "album": {
          "default": {
              "name": "💿 专辑小卡",
              "description": "专辑内附的基础小卡"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          }
      },
      "offline": {
          "default": {
              "name": "💌 标准小卡",
              "description": "正式线下活动获得的小卡"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          },
          "trading": {
              "name": "🤝 交换小卡",
              "description": "专门用于交换的小卡"
          }
      },
      "ADs": {
          "default": {
              "name": "💌 标准小卡",
              "description": "包含的基础小卡。"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          }
      },
      "content": {
          "default": {
              "name": "💌 标准小卡",
              "description": "包含的基础小卡。"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          }
      },
      "misc": {
          "default": {
              "name": "💌 标准小卡",
              "description": "包含的基础小卡。"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          }
      },
      "etc": {
          "default": {
              "name": "💌 标准小卡",
              "description": "包含的基础小卡。"
          },
          "special": {
              "name": "🍀 特典小卡",
              "description": "来自购买商店或特殊地点的小卡"
          },
          "md": {
              "name": "🧸 MD小卡",
              "description": "购买商品时随附的小卡"
          },
          "trading": {
              "name": "🤝 交换小卡",
              "description": "专门用于交换的小卡"
          }
      }
    },
    ReportPhotoCardCategoriesPage: {
      pageName: "新增缺失的小卡",
      title: "小卡类型是什么？"
    },
    ReportPhotoCardConfirmPage: {
      pageName: "新增缺失的小卡",
      title: "小卡类型是什么？",
      confirmedTitle: "新增小卡！",
      confirmedDescription: "小卡已新增，感谢 {{nickname}} 的协助！",
      completedTitle: "报告成功",
      completedDescription: "您将被登记为此小卡的首捕者🧚",
      tagTitle: "添加版本、商店、昵称等信息，让其他粉丝更容易认出您！",
      tagDescription: "若有超过5个标签，仅显示使用最多的标签。",
      tagInputPlaceholder: "例如：珠宝版或热狗小卡",
      addTag: "新增 +"
    },
    ReportPhotoCardMiscActivitiesPage: {
      membershipName: "粉丝俱乐部/会员",
      seasonsName: "季刊",
      lightstickName: "应援棒",
      title: "此小卡来自于哪个活动？",
      unknownEvent: "不确定",
    },
    ReportPhotoCardEventsPage: {
      albumTitle: "这是哪张专辑？",
      contentTitle: "这是什么内容？",
      offlineTitle: "这是哪个官方线下活动？",
      adsTitle: "这是哪项商业活动？",
      defaultTitle: "小卡何时发行？",
      debutedDescription: "{{artistName}} 的出道年份是 <bold>{{debutedYear}}</bold>。",
      addManually: "新增您的 +",
      eventDialogTitle: "请输入专辑名称。",
      eventDialogInputPlaceholder: "专辑、活动名称...",
      albumDescription: "{{description}} #{{volume}}",
    },
    ReportPhotoCardSelectArtistPage: {
      title: "请选择您要报告的艺人。",
    },
    ReportPhotoCardSelectMemberPage: {
      title: "这是哪位成员的照片卡？",
    },
    ReportPhotoCardUploadPhotoPage: {
      title: "上传您的小卡！",
      description: "随着更多粉丝上传小卡，您将可以通过照片搜索它们！",
      disclaimerRowLine1: "请移除卡套或贴纸。",
      disclaimerRowLine2: "拍摄照片时避免光线溢出。",
      disclaimerRowLine3: "粉丝将自动审核并新增！",
      addPhotoCardButton: "打开相机 / 相册",
    },
    ShowTradingProfileBottomDrawer: {
      title: "想更快卖出照片卡？",
      description: "试试公开交易资料吧！",
      openButton: "立即公开",
      skipButton: "下次再说",
    },
    ReportPhotoCardBox: {
      title: "找不到你拥有的 {{memberName}} 的照片卡",
      description: "报告时可获得称号🏆️",
      reportButton: "新增您的小卡"
    }
  }
}
